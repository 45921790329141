<template>
  <Navbar v-if="!showNavbar && !localStorageLoaded" />
  <!-- <div id="app"> -->
  <div v-if="localStorageLoaded">Loading Spinner...</div>
  <router-view v-else></router-view>
  <!-- <router-view /> -->
</template>
<script>

import "./app-assets/css/bootstrap.css";
import "./app-assets/css/main.css";
import "./app-assets/css/bootstrap-extended.css";
import "./app-assets/css/colors.css";
import "./app-assets/css/themes/layout-dark.css";
import "./app-assets/css/pages/authentication.css";
import "./app-assets/fonts/simple-line-icons/style.css";
import "./app-assets/fonts/font-awesome/css/font-awesome.min.css";
import "./app-assets/vendors/css/perfect-scrollbar.min.css";
import "./app-assets/vendors/css/prism.min.css";
import "./app-assets/vendors/css/switchery.min.css";
import "./app-assets/css/components.css";
import "./app-assets/fonts/feather/style.css";
import "./app-assets/vendors/css/datatables/dataTables.bootstrap4.min.css";
import "datatables.net-bs4";
import "jquery";
import "popper.js";
import "bootstrap/dist/js/bootstrap.min.js";

import Navbar from "./components/Navbar.vue";

export default {
  components: {
    Navbar,
  },
  data() {
    return {
      localStorageLoaded: false,
      showSpinner: true,
    };
  },
  computed: {
    showNavbar() {
      return this.$route.meta.showNavbar === true;
    },
  },
  watch: {
    '$store.state.localStorageLoaded'(newVal) {
      this.localStorageLoaded = newVal;
      // Set showSpinner to false when localStorage is loaded
      if (newVal) {
        this.showSpinner = false;
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // Dispatch the Vuex action to load localStorage
      vm.$store.dispatch('loadLocalStorage').then(() => {
        // Set showSpinner to false when localStorage is loaded
        vm.showSpinner = false;
      });
    });
  },
  beforeRouteUpdate(to, from, next) {
    // Reset showSpinner when the route is updated
    this.showSpinner = true;
    next();
  },
};
</script>
<style></style>
