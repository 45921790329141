import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import NotFoundComponent from "./../components/NotFoundComponent.vue";
// import authMiddleware from "@/middlewares/authMiddleware";
import authMiddleware, {
  loadLocalStorageMiddleware,
} from "@/middlewares/authMiddleware"; // Adjust the path accordingly

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { requiresAuth: true }, // Indicate that authentication is required
    beforeEnter: [loadLocalStorageMiddleware, authMiddleware],
  },
  {
    path: "/login",
    name: "login",
    meta: { showNavbar: true },
    component: () => import("../views/authentication/Login.vue"),
    beforeEnter: [loadLocalStorageMiddleware, authMiddleware],
  },
  {
    path: "/forget",
    name: "forget",
    meta: { showNavbar: true },
    component: () => import("../views/authentication/Forget.vue"),
  },
  {
    path: "/resetpassword/:id",
    name: "reset",
    component: () => import("../views/authentication/Resetpassword.vue"),
  },
  // {
  //   path: "/register",
  //   name: "register",
  //   meta: { showNavbar: true },
  //   component: () => import("../views/authentication/Register.vue"),
  // },
  // {
  //   path: "/roles",
  //   name: "roles",
  //   component: () => import("../views/Roles/Roles.vue"),
  //   meta: { requiresAuth: true }, // Indicate that authentication is required
  // },
  // {
  //   path: "/roles/create",
  //   name: "addroles",
  //   component: () => import("../views/Roles/Addroles.vue"),
  //   meta: { requiresAuth: true }, // Indicate that authentication is required
  // },
  // {
  //   path: "/roles/:id",
  //   name: "rolesedit",
  //   component: () => import("../views/Roles/Rolesedit.vue"),
  //   props: true,
  //   meta: { requiresAuth: true }, // Indicate that authentication is required
  // },
  // {
  //   path: "/user/edit/:id",
  //   name: "useredit",
  //   component: () => import("../views/User/Useredit.vue"),
  //   props: true,
  //   meta: { requiresAuth: true }, // Indicate that authentication is required
  // },
  {
    path: "/user",
    name: "user",
    component: () => import("../views/User/User.vue"),
    meta: { requiresAuth: true }, // Indicate that authentication is required
  },
  {
    path: "/feedback",
    name: "feedback",
    component: () => import("../views/FeedbackNWaitlist/Feedback.vue"),
    meta: { requiresAuth: true }, // Indicate that authentication is required
  },
  {
    path: "/waitlist",
    name: "waitlist",
    component: () => import("../views/FeedbackNWaitlist/Waitlist.vue"),
    meta: { requiresAuth: true }, // Indicate that authentication is required
  },
  {
    path: "/notification",
    name: "notification",
    component: () => import("../views/Notifications/index.vue"),
    meta: { requiresAuth: true }, // Indicate that authentication is required
  },
  {
    path: "/events",
    name: "events",
    component: () => import("../views/Events/index.vue"),
    meta: { requiresAuth: true }, // Indicate that authentication is required
  },
  {
    path: "/cultures",
    name: "cultures",
    component: () => import("../views/Cultures/index.vue"),
    meta: { requiresAuth: true }, // Indicate that authentication is required
  },
  // {
  //   path: "/user/create",
  //   name: "usercreate",
  //   component: () => import("../views/User/Adduser.vue"),
  // },
  // {
  //   path: "/user/show/:id",
  //   name: "userprofile",
  //   component: () => import("../views/User/Userprofile.vue"),
  // },
  {
    path: "/user/loginsessions/:id",
    name: "loginsession",
    component: () => import("../views/User/Loginsession.vue"),
  },
  {
    path: "/:catchAll(.*)",
    component: NotFoundComponent,
    meta: { showNavbar: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(authMiddleware);

// router.beforeEach((to, from, next) => {
//   // Check if the route requires authentication
//   console.log({ to });
//   if (to.matched.some((record) => record.meta.requiresAuth)) {
//     // Check if the user is authenticated
//     const isAuthenticated = localStorage.getItem("token");
//     if (!isAuthenticated) {
//       // Redirect to the login page if not authenticated
//       next("/login");
//     } else {
//       // Continue with the navigation if authenticated
//       next();
//     }
//   } else {
//     // Continue with the navigation for routes that don't require authentication
//     next();
//   }
// });

export default router;
