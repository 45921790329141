import { createStore } from "vuex";

export default createStore({
  getters: {},
  state: {
    localStorageLoaded: false,
  },
  mutations: {
    setLocalStorageLoaded(state) {
      state.localStorageLoaded = true;
    },
  },
  actions: {
    loadLocalStorage({ commit }) {
      // Simulate loading from localStorage (replace this with your actual logic)
      setTimeout(() => {
        // Set the localStorageLoaded state to true when the loading is complete
        commit("setLocalStorageLoaded");
      }, 1000);
    },
  },
  modules: {},
});
